import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

function AboutPage() {
  return (
    <Layout>
      <SEO
        title="About"
        keywords={['gatsby', 'tailwind', 'react', 'tailwindcss']}
      />

      <div className="flex flex-col md:flex-row items-center">
        <div className="text-teal-200">
          <div className="font-serif leading-loose text-justify md:flex">
            <p className="border-l-4 border-teal-800 pl-4 mr-4">
              Fredrik is dedicated to finding the best solutions possible, he works in
              a systematic way and when complexity is high, Fredrik puts in all his efforts
              to find the best way ahead. He is highly motivated, curious and willingly takes
              on the role as a leader. Fredrik both thinks and works with a long-term view and
              his clients feel confident that he will always deliver. He truly inspires with
              his high level of ambition and quality standard - he is a person you really want
              to have in your team.
            </p>
            <p className="border-l-4 border-teal-800 pl-4 mr-4">
              Fredrik is an innovative developer, he is an analytical and target-oriented
              team member who is easy to collaborate with. He has the capacity to explain
              complex contexts in a pedagogical way, he is a highly qualified and
              intelligent consultant who works well under time pressure. Fredrik has an
              impressive talent to quickly assimilate new knowledge and a unique understanding
              of complex systems. He shows diplomacy and an unmistakable gift to get the team
              to walk the extra mile.
            </p>
          </div>

          <p className="font-bold mt-4 text-right text-xs uppercase">
            – Abstract based upon the responses from 10 colleagues and customers responding
             anonymously in May 2017 analyzed by Per Frykman - Your Professional Reputation Inc
          </p>
        </div>

        <div className="w-2/3 md:w-1/3" />
      </div>
    </Layout>
  );
}

export default AboutPage;
